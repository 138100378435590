var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "member-details" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c("a-col", [
            _c("img", {
              attrs: { src: require("@/assets/custom-icons/email.png") }
            })
          ]),
          _c("a-col", [
            _c("label", [_vm._v("Email")]),
            _c("p", [_vm._v(_vm._s(_vm.member.work_email))])
          ])
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c("a-col", [
            _c("img", {
              attrs: { src: require("@/assets/custom-icons/calendar.png") }
            })
          ]),
          _c("a-col", [
            _c("label", [_vm._v("Date of Birth")]),
            _c("p", [_vm._v(_vm._s(_vm.nFormat(_vm.member.dob)))])
          ])
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c("a-col", [
            _c("img", {
              attrs: { src: require("@/assets/custom-icons/id-card.png") }
            })
          ]),
          _c("a-col", [
            _c("label", [_vm._v("Member ID Number")]),
            _c("p", [_vm._v("N/A")])
          ])
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c("a-col", [
            _c("img", {
              attrs: { src: require("@/assets/custom-icons/mobile-app.png") }
            })
          ]),
          _c("a-col", [
            _c("label", [_vm._v("Phone")]),
            _c("p", [_vm._v(_vm._s(_vm.member.phone))])
          ])
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c("a-col", [
            _c("img", {
              attrs: { src: require("@/assets/custom-icons/address.png") }
            })
          ]),
          _c("a-col", [
            _c("label", [_vm._v("Address")]),
            _c("p", [
              _vm.member.address_1
                ? _c("span", [_vm._v(_vm._s(_vm.member.address_1))])
                : _vm._e(),
              _vm.member.address_2
                ? _c("span", [_vm._v(", " + _vm._s(_vm.member.address_2))])
                : _vm._e(),
              _vm.member.town
                ? _c("span", [_vm._v(", " + _vm._s(_vm.member.town))])
                : _vm._e(),
              _vm.member.region
                ? _c("span", [_vm._v(", " + _vm._s(_vm.member.region))])
                : _vm._e(),
              _vm.member.postcode
                ? _c("span", [_vm._v(", " + _vm._s(_vm.member.postcode))])
                : _vm._e()
            ])
          ])
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c("a-col", [
            _c("img", {
              attrs: { src: require("@/assets/custom-icons/customer.png") }
            })
          ]),
          _c("a-col", [
            _c("label", [_vm._v("Emergency Contact")]),
            _c("p", [_vm._v(_vm._s(_vm.member.emergency_name))])
          ])
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c("a-col", [
            _c("img", {
              attrs: { src: require("@/assets/custom-icons/phone-call.png") }
            })
          ]),
          _c("a-col", [
            _c("label", [_vm._v("Emergency Number")]),
            _c("p", [_vm._v(_vm._s(_vm.member.emergency_phone))])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }